import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Header from './components/Header.jsx';
import Footer from './components/Footer.jsx';

// Lazy load components
const Home = React.lazy(() => import('./pages/Home'));
const BlogPostPage = React.lazy(() => import('./pages/blog/page.jsx'));
const Login = React.lazy(() => import('./pages/login/page.jsx'));
const Register = React.lazy(() => import('./pages/register/page.jsx'));
const DashboardLayout = React.lazy(() => import('./pages/dashboard/layout.jsx'));

// Loading fallback component
const LoadingFallback = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
  </div>
);

// Create a client
const queryClient = new QueryClient();

function AppContent() {
  const location = useLocation();
  const isDashboardRoute = location.pathname.startsWith('/dashboard');

  return (
    <>
      {!isDashboardRoute && <Header />}
      <div className='App'>
        <Suspense fallback={<LoadingFallback />}>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/blog/:blogPostId/:slug' element={<BlogPostPage />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/dashboard/*' element={<DashboardLayout />}></Route>
          </Routes>
        </Suspense>
      </div>
      {!isDashboardRoute && <Footer />}
    </>
  );
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AppContent />
      </Router>
    </QueryClientProvider>
  );
}

export default App;