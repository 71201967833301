'use client';

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
// import bg from '../../public/assets/images/movaex__header__logo.png'

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navItems = [
    { href: '/', label: 'Home' },
    // { href: '/blog', label: 'Blog' },
    { href: 'https://movaex.com/about', target: '_blank', label: 'About' },
    { href: 'https://movaex.com/', target: '_blank', label: 'Services' },
  ];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className='fixed w-full z-50 bg-white/90 backdrop-blur-md shadow-md'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
        <div className='flex justify-between items-center py-4 md:space-x-10'>
          {/* Logo Section */}
          <div className='flex justify-start lg:w-0 lg:flex-1'>
            <Link to='/' className='flex items-center'>
              <img
                src='/assets/images/movaex__header__logo.webp'
                alt='Movaex Logo'
                width={150}
                height={40}
                className='w-[100px] md:w-[150px] h-auto object-contain' // Responsive sizes
              />
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <div className='-mr-2 -my-2 md:hidden'>
            <button
              type='button'
              onClick={toggleMenu}
              className='bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500'
            >
              <span className='sr-only'>Open menu</span>
              {isMenuOpen ? (
                <X className='h-6 w-6' />
              ) : (
                <Menu className='h-6 w-6' />
              )}
            </button>
          </div>

          {/* Desktop Navigation */}
          <nav className='hidden md:flex space-x-6'>
            {navItems.map((item) => (
              <a
                key={item.href}
                href={item.href}
                target={item.target}
                className='text-base font-medium text-gray-600 hover:text-movaexColor transition-colors duration-300 ease-in-out relative group'
              >
                {item.label}
                <span className='absolute bottom-0 left-0 w-0 h-0.5 bg-movaexColor transition-all duration-300 group-hover:w-full'></span>
              </a>
            ))}
          </nav>
        </div>

        {/* Mobile Menu Dropdown */}
        {isMenuOpen && (
          <div className='md:hidden'>
            <div className='px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-white shadow-lg rounded-b-lg'>
              {navItems.map((item) => (
                <a
                  key={item.href}
                  href={item.href}
                  className='text-gray-600 hover:bg-blue-50 hover:text-movaexColor block px-3 py-2 rounded-md text-base font-medium transition-all'
                  onClick={toggleMenu}
                >
                  {item.label}
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;