import React from 'react';
import { Facebook, Twitter, Instagram, Linkedin } from 'lucide-react';

function Footer() {
  return (
    <footer className="bg-white shadow-lg border-t py-12">
      <div className="container mx-auto px-6 max-w-7xl">
        {/* Logo or Site Title */}
        <div className="text-center mb-8">
          <h2 className="text-4xl font-semibold text-movaexColor tracking-wide">
            Movaex Insights
          </h2>
        </div>

        {/* Links Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 text-center md:text-left">
          <div>
            <h4 className="font-semibold text-xl mb-4 text-movaexColor">
              Quick Links
            </h4>
            <ul className="space-y-3">
              <li>
                <a
                  href="https://movaex.com/about"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-movaexColor transition-colors"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="https://movaex.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-movaexColor transition-colors"
                >
                  Services
                </a>
              </li>
              <li>
                <a
                  href="https://movaex.com/contact"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-movaexColor transition-colors"
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="font-semibold text-xl mb-4 text-movaexColor">
              Contact Info
            </h4>
            <ul className="space-y-3">
              <li>
                <a
                  href="https://www.google.com/maps/search/?api=1&query=18+Marcus+Garvey+St,+New+Haven,+Enugu+400102,+Enugu"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-movaexColor transition-colors"
                >
                  18 Marcus Garvey St, New Haven, Enugu 400102, Enugu
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.com/maps/search/?api=1&query=67+Tombia+St,+new+GRA+port+harcourt+500272,+rivers,+nigeria"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-movaexColor transition-colors"
                >
                  67 Tombia St, new GRA port harcourt 500272, rivers, nigeria
                </a>
              </li>
              <li>
                <a
                  href="tel:+2349068063045"
                  className="hover:text-movaexColor transition-colors"
                >
                  +234 906 806 3045
                </a>
              </li>
              <li>
                <a
                  href="tel:+2348062461244"
                  className="hover:text-movaexColor transition-colors"
                >
                  +234 806 246 1244
                </a>
              </li>
              <li>
                <a
                  href="mailto:support@movaex.com"
                  className="hover:text-movaexColor transition-colors"
                >
                  support@movaex.com
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="font-semibold text-xl mb-4 text-movaexColor">
              Follow Us
            </h4>
            <div className="flex justify-center md:justify-start space-x-6">
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-movaexColor transition-colors"
              >
                <Facebook size={24} />
              </a>
              <a
                href="https://www.twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-movaexColor transition-colors"
              >
                <Twitter size={24} />
              </a>
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-movaexColor transition-colors"
              >
                <Instagram size={24} />
              </a>
              <a
                href="https://www.linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-movaexColor transition-colors"
              >
                <Linkedin size={24} />
              </a>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-12 border-t border-gray-200 pt-4 text-center text-sm">
          <p>
            &copy; {new Date().getFullYear()} Movaex Insights. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;